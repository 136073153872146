.App {
  text-align: center;

}

body {
  background-color: #212429;  
  /* background-color: #1c1d1f; */
  /* #17181A */
  /* #121212 */
  color: white;
  font-family: Poppins, sans-serif;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.test{
  background-color: rgba(0, 255, 255, 0.199);
  /* display: block; */
  height: 70vh;
  background-image: radial-gradient( rgba(255, 255, 255, 0.527), rgba(255, 255, 255, 0.267) ,#282c34);
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-container{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;

  /* flex-wrap: wrap; */
}

/* .thumbnail-content{
  font-size: 5vw;
} */

.my-card{
  max-width: 100%;
  border: 1px;
  margin-bottom:5px;
  background-color: inherit;  
  
  font-weight: bolder;
  font-size: 3rem;

}

.reusable{
  color:white;
  background-color: #17181A;
  border-radius: 1vw;
}

.flex-container2{
  display:flex;
  flex-direction: row;
  /* flex-grow: 1; */
  justify-content: center;
  align-items: center;
  width:100%;
  flex-wrap: wrap;
}

.project-card{
  margin: 15px 30px;
}




.vertical-card-container {
  display: flex;
  flex-direction: column;
  width: 450px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  background-color: #131518;  
}

/* Image */
.card-image {
  width: 100%;
  height: auto;
}

/* Content */
.card-content {
  padding: 20px;
  color: white;
}

/* Title */
.card-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

/* Description */
.card-description {
  font-size: 1rem;
  text-align: left;
}


.horizontal-card-container {
  display: flex;
  flex-direction: row;
  width: 75vw;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  background-color: #131518;  

}

.horizontal-card-container .card-image {
  width: 300px;
  height: 250px;
}

/* Content */
.horizontal-card-container .card-content {
  padding: 20px;
  color: white;
}

/* Title */
.horizontal-card-container .card-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

/* Description */
.horizontal-card-container .card-description {
  font-size: 1rem;
}

.skill-tag{
  padding: 3px 10px;
  background-color: gray;
  margin: 3px; 
  border-radius: 20vw;
  font-size: 12px;
  display: inline-block;
}

.a-flex{
  display:flex;
  justify-content: left;
  flex-wrap: wrap;
}


.nav-flex{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width:90%;
}

.nav-content-left{
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: left;
  margin-left:5vw;
}

.nav-content-right{
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: right;
}

.devwhiz-logo{
  color:rgb(0, 119, 255);

}

.nav-content-right ul li{
  font-size: large;
}

.brand-logo{
  text-decoration: None;
  font-size: xx-large;
  color:white;
}

.skill-tag-wrapper{
  display:block;
  width:auto;
  height: 70px;
}


.service-icons{
  width:100px;
  height:100px;
  overflow:hidden;
}

.nav-item a:hover{
  font-size: 98%;
}

.vertical-card-container:hover{
  box-shadow: 1px 1px 1px 1px rgb(43, 43, 43);
}

.vertical-card-container img:hover{
  opacity:75%;
}

.contact-card-left{
  width:60%;
}
.contact-card-right{
  width:40%;
}

.contact-card-left-content{
  margin: 20px 40px;
  text-align: left;
}

.contact-icon{
  width:40px;
  height:40px;
  margin: 50px 10px;
}

.contact-card-right img:hover{
  opacity: 0.8;
  
}

.centered-img-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 100px;
  font-weight:bold;
  color:rgba(255, 255, 255, 0.911);
}

.img-h1{
  margin:1px 0px;
  font-size: 100px;
  font-weight:bolder;

  background: -webkit-linear-gradient(#eee, #0452c7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h1{
  font-size:60px;
}

hr{
  width:40vw;
  margin:0px auto;
  margin-bottom:20px;
}

.vertical-card-container img:hover{
  opacity: 0.7;
}

.own-container{
  margin: 0px 100px;
}

.vertical-card-container img{
  width: 100%;
  height:300px;
  max-height:300px;
}

.thumbnail-image{
  height:90vh; 
  width: 100vw;
  opacity: 0.2
}



              /* media Queries  */





/* Mobile Screen */
@media (max-width: 560px) {
  h1{
    font-size:35px;
  }
  .img-h1{
    font-size:50px;
  }
  /* .vertical-card-container img{
    width: 100%;
    height:300px;
    max-height:300px;
  } */
  .own-container{
    margin: 0px 10px;
  }

  .horizontal-card-container {
    display: flex;
    flex-direction: column;
    width: 90vw;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    background-color: #131518;  
  
  }
  .contact-card-left{
    width:100%;
  }
  .contact-card-right{
    width:100%;
  }
  .contact-icon{
    width:30px;
    height:30px;
    margin: 40px 05px;
  }

  .thumbnail-image{
    height:50vh; 
    width: 100vw;
    opacity: 0.2
  }

  .centered-img-text {
    position: absolute;
    top: 25%;
    left: 25%;
    transform: translate(-25%, -25%);
    font-size: 100px;
    font-weight:bold;
    color:rgba(255, 255, 255, 0.911);
    margin-left:10px;
  }
  .skill-tag-wrapper{
    display:block;
    width:auto;
    height: 85px;
  }
  .contact-card-left-content{
    margin: 20px 20px;
    text-align: left;
  }
}


/* Laptop Screen  */
@media (max-width: 1688px) {
  .own-container{
    margin: 0px 10px;
  }
  
}

@media (max-width: 1505px) {
  .own-container{
    margin: 0px 1px;
  }

  .horizontal-card-container {
    display: flex;
    /* flex-direction: column; */
    width: 100vw;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    background-color: #131518;  
  
  }
  
}

